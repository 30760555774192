<template>
	<div>
		<el-card>
			<el-row :gutter="20" class="left">
				<el-col :span="24">
					<span style="color: #666">评价管理</span>
				</el-col>
			</el-row>
			<el-divider></el-divider>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true" :model="queryForm" ref="reference">
						<el-form-item prop="techName">
							<el-input v-model="queryForm.techName" placeholder="请输入技师名称" clearable></el-input>
						</el-form-item>
						<el-form-item prop="orderNumber">
							<el-input v-model="queryForm.orderNumber" placeholder="请输入服务订单号" clearable></el-input>
						</el-form-item>
						<el-form-item prop="options">
							<el-select v-model="queryForm.options" placeholder="请选择星级">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-table :data="comment_list" 
				:default-sort="{prop:'add_time',order:'descending'}" 
				style="width: 100%"
				:header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column fixed label="ID" prop="record_id" width="100"></el-table-column>
				<el-table-column label="服务项目信息" width="280">
					<template slot-scope="scope">
						<div class="service">
							<div class="service_img"><img :src="scope.row.service_info.service_logo" alt=""></div>
							<div class="service_info">
								<div class="info">{{scope.row.service_info.service_name}}</div>
								<div class="price_count">
									<div class="price">￥{{scope.row.service_info.service_price}}</div>
									<div class="count">x{{scope.row.service_info.service_number}}</div>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="custom_id" label="客户ID" width="120"></el-table-column>
				<el-table-column prop="nickName" label="客户昵称" width="150"></el-table-column>
				<el-table-column prop="jishi_name" label="技师" width="120"></el-table-column>
				<el-table-column label="评价星级" width="180">
					<template slot-scope="scope">
						<el-rate v-model="scope.row.comment_level" :disabled="true">
						</el-rate>
					</template>
				</el-table-column>
				<el-table-column prop="comment_content" label="评价内容" width="180"></el-table-column>
				<el-table-column label="评价标签" width="230">
					<template slot-scope="scope">
						<el-tag class="rightb" v-for="(item,index) in scope.row.label_list" :key="index">
							{{item}}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="add_time" label="评价时间" width="180"> </el-table-column>
				<el-table-column fixed="right" label="操作" width="100">
					<template slot-scope="scope">
						<div class="btns">
							<el-button size="mini" type="danger" plain @click="removeComment(scope.row.record_id)">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				total: 0,
				queryForm: {
					techName: "",
					orderNumber: "",
					options: "0",
				},
				options: [{
						value: "0",
						label: "全部"
					},
					{
						value: "5",
						label: "五星"
					},
					{
						value: "4",
						label: "四星"
					},
					{
						value: "3",
						label: "三星"
					},
					{
						value: "2",
						label: "二星"
					},
					{
						value: "1",
						label: "一星"
					},
				],
				comment_list:[]
			};
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getCommentList();
			}	
		},
		methods: {
			getCommentList(){
				var url = 'order/comment_list';
				let params = {
					jishi_name: this.queryForm.techName,
					order_sn: this.queryForm.orderNumber,
					comment_level: this.queryForm.options,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.comment_list = res.list;
						console.log(res.list);
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1
				this.queryInfo.pagesize = newSize
				this.getCommentList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage
				this.getCommentList();
			},
			//搜索
			search() {
				this.getCommentList();
			},
			//重置
			reset() {
				this.$refs["reference"].resetFields();
				this.getCommentList();
			},
			//删除
			removeComment(id) {
				this.$confirm('你确定要删除吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(timer != null){clearTimeout(timer);}
        			timer = setTimeout(() => {
						//删除操作
						var url = 'order/delete_comment';
						let params = {
							record_id: id,
						};
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.$message.success("操作成功");
								that.getCommentList();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					},200);
				}).catch(() => {
					this.$message.info('已取消删除');
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	@import "./Order_commentManage.scss";
</style>